<template>
  <div>
    <v-tooltip
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Year</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="900"
    >
      <v-card
        max-height="740"
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          Year Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>

              <v-row dense>
                <!--Projects-->
                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <v-select
                    v-model="id_project"
                    :items="projectsStore.projectsValueList"
                    label="Project"
                    placeholder=" "
                    item-value="id"
                    item-text="code"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <v-text-field
                    v-model="number"
                    label="Year Number"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Start Date-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_start"
                        label="Start Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_start"
                        v-on="on"
                        @blur="date1 = $_parseDate(date_start)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      color="primary"
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>

                <!--End Date-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_end"
                        label="End Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_end"
                        v-on="on"
                        @blur="date2 = $_parseDate(date_end)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date2"
                      color="primary"
                      @input="menu2 = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'yearsStore/getEntryField',
  mutationType: 'yearsStore/updateEntryField',
});

export default {
  name: 'Year',
  mixins: [mixin],
  data() {
    return {
      menu: false,
      menu2: false,
      rules: {
        date_start: [
			    v => !!v || 'Required',
        ],
        date_end: [
			    v => !!v || 'Required',
        ],
      },
    }
  },
  computed: {
    ...mapState({
      yearsStore: state => state.yearsStore,
      valueListsStore: state => state.valueListsStore,
      projectsStore: state => state.projectsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',

      'id_project',
      'date_start',
      'date_end',
      'number',
      'date1',
      'date2',
      'flag_show'
    ]),
  },

  watch: {
    date1() {
      this.date_start = this.$_formatDate(this.date1);
    },
    date2() {
      this.date_end = this.$_formatDate(this.date2);
    },
  },

  methods: {

    async newEntry() {
      this.$store.dispatch('yearsStore/resetEntry');
      await this.$store.dispatch('projectsStore/valueList');
      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['yearsStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('yearsStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('yearsStore/update', entry);
        }
        this.cancelEntry();
        /* Grab filters */
        const filters = this.$store.getters['yearsStore/getFilters'];
        /* Grab years based on active filters */
        await this.$store.dispatch('yearsStore/fetch', filters);
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
